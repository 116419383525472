import utils from 'o365.modules.utils.js';
import translate from 'o365.modules.translate.ts';
import appInsights from 'o365.modules.AppInsights.ts';
import vTarget from 'o365.vue.directive.target.js';

// Adds utility property types for component instances
// @ts-ignore
declare module 'vue' {
    interface ComponentCustomProperties {
        $format: typeof utils.format,
        $formatDate: typeof utils.formatDate,
        $formatNumber: typeof utils.formatNumber,
        $formatFileSize: typeof utils.formatFileSize,
        
        /** Function for translating strings */
        $t: typeof translate,
        $track: typeof appInsights.trackEvent,
    }
}

export default {
    install: (app, options: {
        includeProperties: boolean,
        includeDirectives: boolean,
    }) => {
        if (options?.includeProperties ?? true) {
            app.config.globalProperties.$format = utils.format;
            app.config.globalProperties.$formatDate = utils.formatDate;
            app.config.globalProperties.$formatNumber = utils.formatNumber;
            app.config.globalProperties.$formatFileSize = utils.formatFileSize;
            app.config.globalProperties.$track = appInsights.trackEvent;
            app.config.globalProperties.$t = translate;
        }

        if (options?.includeDirectives ?? true) {
            app.directive('target', vTarget);
            app.directive('focus', {
                mounted(el, binding) {
                    const focus = () => {
                        if (binding.modifiers.input) {
                            el.querySelector('input')?.focus();
                        } else {
                            el.focus();
                        }
                    };

                    if (binding.arg) {
                        const timeout = parseInt(binding.arg) || 0;
                        window.setTimeout(() => {
                            focus();
                        }, timeout);
                    } else {
                        focus();
                    }
                }
            });
        }
    }
}
